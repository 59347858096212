import { Link } from "gatsby"
import React from "react"
import SEO from "../components/seo"
import "../styles/settings.scss"
import "../styles/style.scss"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="site-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title">
              <h2>NOT FOUND</h2>
              <p style={{ color: "rgba(255, 255, 255, 0.54)" }}>
                No AI was harmed during this page creation.
              </p>
              <Link
                to="/"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default NotFoundPage
